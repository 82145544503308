@import url('./variables.css');

body {
    margin: 0;
    font-family:  var(--ubuntu-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: var(--default-letter-spacing);
    line-height: var(--default-line-height);
    background-color: var(--content-background-color);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    color: var(--black);
}

table, td, tr, th {
  border: 0;
  border-spacing: 0;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

/* The sidebar menu */
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 200px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: var(--sidenav-background-color); /* Black */
  color: var(--sidenav-color);
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
}

.sidebar-items {
  height: calc(100% - 100px) !important;
  padding-top: 10px;
}

/* The navigation menu links */
.sidenav a {
  padding: .25rem .5rem;
  text-decoration: none;
  font-size: 15px;
  color: var(--sidenav-color);
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: var(--sidenav-active-color);
}

/* Style page content */
.main {
  margin-left: 200px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 200px;
  z-index: 1030;
}

@media screen and (max-width: 770px) {
  .main {
    margin-left: 0px; /* Same as the width of the sidebar */
  }

  .fixed-top {
    left: 0px;
  }

  .fixed-bottom-dropdown {
    display: none !important;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 0px;}
  .sidenav a {font-size: 15px;}
}



.content-container {
  margin-top: 60px;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* typography */

h1, h2, h3, h4, h5, h6 {
  font-family: var(--ubuntu-semibold);
  line-height: 22px !important;
}

p, span, a {
  font-family: var(--ubuntu-regular);
  line-height: 22px !important;
}

.sidenav p.bold, .sidenav span.bold, .sidenav a.bold {
  font-family: var(--sidenav-active-font);
  line-height: 22px !important;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

p{
  font-size: 14px;
}

.form-control:focus, .form-select:focus, .form-check-input:focus {
  outline: 0;
  box-shadow: unset !important;
}

.form-control::placeholder{
  color: var(--grey);
}

button:focus{
  outline: 0 !important;
  box-shadow: unset !important;
}

/* sidebar  */

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.sidenav .btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: normal;
  color: var(--sidenav-active-color);
  background-color: transparent;
  border: 0;
  font-family: var(--sidenav-active-font);
  letter-spacing: .03em;
  font-size: 15px;
}

.sidenav .btn-toggle.collapsed {
  font-family: var(--sidenav-normal-font);
  color: var(--sidenav-color);
}

.sidenav .btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: ">" !important;
  transition: transform .35s ease;
  transform-origin: .5em 50%;
  color: var(--sidenav-color);
}

.sidenav .btn-toggle:hover,
.sidenav .btn-toggle:focus {
  font-family: var(--roboto-semibold);
  background-color: #d2f4ea;
}

.sidenav .btn-toggle[aria-expanded="true"] {
  color: var(--sidenav-active-color);
}
.sidenav .btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.sidenav .btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.sidenav .btn-toggle-nav a:hover,
.sidenav .btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.sidenav .btn-toggle-nav a:hover, .sidenav .btn-toggle-nav a:focus {
  color: var(--sidenav-active-color);
  font-family: var(--sidenav-active-font);
}

.sidenav .active {
  color: var(--sidenav-active-color);
  font-family: var(--sidenav-active-font);
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: transparent !important;
}

.sidenav .btn-toggle:hover, .sidenav .btn-toggle:focus {
  color: var(--sidenav-active-color);
  background-color: transparent !important;
}

.sidenav a:hover {
  font-family: var(--sidenav-active-font);
}

.fixed-bottom-dropdown{
  font-weight: normal !important;
  position: fixed;
  background-color: var(--sidenav-background-color);
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  width: 200px;
  border-top: 1px solid rgb(211,211,211);
  padding: 5px 5px;
}

.sidebar-items a {
  font-family: var(--sidenav-normal-font);
  color: var(--sidenav-color);
  font-size: 15px;
  letter-spacing: .03em;
}

.sidenav .fixed-bottom-dropdown a {
  font-family: var(--ubuntu-regular);
  color: var(--sidenav-color);
  font-size: 15px;
  font-weight: normal !important;
}

.sidenav .fixed-bottom-dropdown .dropdown-item{
  color: var(--theme-color) !important;
}

.sidenav .btn-toggle-nav a {
  margin-left: .5rem !important;
}

.form-label {
  font-family: var(--roboto-semibold);
  color: var(--bs-gray);
}

input::placeholder{
  font-family: var(--ubuntu-regular);
  font-weight: normal !important;
}

button{
  font-family: var(--ubuntu-regular);
}

.text-gray-italic {
  color: var(--grey) !important;
  font-size: 12px;
  font-family: var(--ubuntu-light-italic);
}

.text-gray {
  color: var(--grey) !important;
  font-size: 12px;
  font-family: var(--ubuntu-regular);
}

.sidenav .dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important;
}

.sidenav .dropdown-item:hover, .dropdown-item:focus {
  color: var(--theme-color);
  background-color: transparent !important;
  font-family: var(--sidenav-active-font) !important;
}

.btn-success {
  background-color: var(--green-normal) !important;
}

.btn-success:hover {
  background-color: var(--green-dark) !important;
}

.btn-danger {
  background-color: var(--red-normal) !important;
}

.btn-danger:hover {
  background-color: var(--red-dark) !important;
}

.btn-primary {
  background-color: var(--blue-normal) !important;
}

.btn-default {
  background-color: var(--secondary-color) !important;
  color: var(--white);
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  background-color: var(--secondary-color-dark) !important;
  color: var(--white);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--blue-dark) !important;
}

.bg-body h6{
  color: var(--secondary-color);
}

.text-default {
  color: var(--theme-color);
}

.text-secondary {
  color: var(--secondary-color);
}

/* modals */

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
  left: 0px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
  left: 0;
}
      
/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #EEEEEE;
  background-color: var(--theme-color);
  color: var(--white);
  border-radius: 0;
}

.modal-header .btn-close {
  background: transparent !important;
  color: var(--secondary-color);
  font-size: 22px;
}

/* page loading canvas */

.loading-page {
  width: 100%;
  height: 100vh;
  background-color: var(--theme-color);
}
.loading-page .center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 47%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
}

/* table */

.table {
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  border-top: .2rem solid #d8dbe0;
  padding: .5rem;
  border-radius: .3rem;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: .8rem;
  line-height: 1.42857143;
}

table thead th {
  font-size: 0.9rem;
  font-family: var(--roboto-semibold);
  text-align: left;
  padding-bottom: 5px;
}

.table>thead>tr>th {
  border-bottom: .12rem solid #f2f2f2;
  border-bottom-color: #f2f2f2 !important;
}

table tbody td {
  font-size: .9rem;
}

table tbody tr:focus-within, table tbody tr:hover {
  background-color: #f5f5f5;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th {
  border-top: .1rem solid #ebedef;
}

.table-action-dropdown .dropdown-toggle {
  background: transparent;
  color: var(--theme-color);
  border: none;
  border-radius: 12px;
}

.table-action-dropdown .dropdown-toggle:hover, 
.table-action-dropdown .dropdown-toggle:focus {
  background: var(--theme-color);
  color: #fff;
  border-radius: 12px;
}

.table-action-dropdown .dropdown-toggle::after {
  content:none;
}

.table-action-dropdown .dropdown-item {
  color: var(--theme-color);
}

.table-action-dropdown .dropdown-item:hover, 
.table-action-dropdown .dropdown-item:focus {
  background: transparent;
  font-family: var(--roboto-semibold);
}

.table-bottom-center-pagination {
  text-align: center !important;
}

.table-bottom-center-pagination .pagination{
  display: inline-flex !important;
}

.table-bottom-center-pagination .pagination .page-link{
  color: var(--theme-color);
}

.table-bottom-center-pagination .pagination .page-link:hover,
.table-bottom-center-pagination .pagination .page-link:focus{
  color: var(--secondary-color);
  background: var(--theme-color);
}

.table-container {
  margin-top: 10px !important;
  display: inline-block;
}

.low-height-btn {
  line-height: 0.9 !important;
  display: inline;
}

.highlighted-text {
  color: var(--secondary-color) !important;
}

.text-right {
  text-align: right !important;
}

.modal-footer {
  padding: 0.4rem .75rem !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: var(--light-grey);
}

.modal.left .modal-body, .modal.right .modal-body {
  padding: 15px 15px 15px;
}